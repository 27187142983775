<template>
  <div class="thumbnails">
    <div class="banner thumbnails__imgWrap thumbnails__imgWrap_full">
      <img class="banner__img thumbnails__img" src="../assets/img/banners/pending-banner.jpg"
           alt="application status">
    </div>
    <div class="form form_box">
      <h1 class="titlePage titlePage_textLeft">WE ARE STILL WORKING ON YOUR APPLICATION!</h1>
      <p class="text text_default">Your application is being reviewed by Starline Security. We will be in touch as soon as your
        application assessment has been completed. <u>For further information contact us on 07 3272 2974.</u></p>
      <p class="text text_italic">Thank you for your patience.</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Pending"
  }
</script>

<style scoped>

</style>
